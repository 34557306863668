import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { BackgroundTexture } from '../components/background-texture';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { ClientReview } from '../components/client-review';
import { InstagramWidget } from '../components/instagram-widget';
import { ContactSection } from '../components/contact-section';
import { CTA } from '../components/cta';
import ServicesCard from '../components/services-card';
import { FAQs } from '../components/faqs';
import { AboutSection } from '../components/about-section';
import { browTattoo } from '../components/services/brow-tattoo';
import { ConsultationForm } from '../components/consultation-form';

function CosmeticTattooingPage(): React.ReactElement {
  return (
    <>
      <SEO
        title="Cosmetic Tattooing "
        description="Longing for an amazing organic facelift that you desire. Adore Beauty and Cosmetic Tattoo creates an experience like no other with our exquisite beauty treatment. Jess, the founder of Adore Beauty and Cosmetic Tattoo, has more than 18 years in the beauty industry and specialises in brow makeovers."
      />
      <Layout>
        <Hero />
        <About />
        <BrowTattoo />
        <ConsultationForm />
        <FAQs />
        <ClientReview />
        <CTA text="In need of self care?" path="/contact-us/#contact" />
        <InstagramWidget />
        <ContactSection />
      </Layout>
    </>
  );
}

function Hero(): React.ReactElement {
  return (
    <div className="relative hidden max-h-screen sm:block">
      {/* <div className="absolute md:hidden -top-24">
        <StaticImage
          quality={90}
          width={700}
          src="../images/hero-bg.png"
          alt=""
          className="flex-1 w-full object-full"
        />
      </div> */}
      <div className="absolute inset-0 flex">
        <StaticImage
          quality={90}
          src="../images/tattoo-hero-V2.jpg"
          alt=""
          className="flex-1 object-cover"
        />
        <div className="absolute inset-0 flex">
          <div className="flex-1 hero-bg-filter" />
        </div>
      </div>
      <div className="relative w-full px-4 py-24 mx-auto md:py-48 sm:px-6 lg:px-8 max-w-screen-2xl">
        <h1 className="text-4xl font-semibold text-center md:text-left text-custom-gradient">
          Cosmetic Tattooing
        </h1>
      </div>
    </div>
  );
}

function About() {
  const description = (
    <div>
      <p>
        Cosmetic tattooing, also known as permanent makeup, can deliver many
        benefits to women of all ages. The ability to semi-permanently enhance
        your appearance, while achieving a natural look not only saves on time
        daily but is the perfect option for correcting over plucked brows or
        pale patchy lips.
      </p>
    </div>
  );
  return (
    <AboutSection
      cta="Enquire Now"
      ctaPath="/cosmetic-tattooing/#consultation-form"
      description={description}
      image={
        <StaticImage
          quality={90}
          src="../images/brows/combination-brow.jpg"
          alt=""
          className="flex-1 rounded-full safari-hack"
        />
      }
    />
  );
}

function BrowTattoo(): React.ReactElement {
  return (
    <div className="relative block w-full bg-pink-lightest">
      <BackgroundTexture position="left" />
      <div className="relative px-4 py-16 mx-auto space-y-5 md:py-24 max-w-screen-2xl sm:px-6 lg:px-8">
        <div>
          <h2 className="mb-8 text-3xl font-semibold text-center">
            <span className="block text-custom-gradient">
              Cosmetic Brow Tattooing
            </span>
          </h2>

          {browTattoo.map((item) => (
            <div key={item.id}>
              <ServicesCard
                name={item.name}
                price={item.price}
                description={item.description}
                image={item.image}
                buttonOutline={item.buttonOutline}
                buttonLink={item.buttonLink || '/contact-us/#contact'}
                imagePosition="right"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CosmeticTattooingPage;
