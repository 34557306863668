import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { nanoid } from 'nanoid';

export const browTattoo = [
  {
    id: nanoid(),
    name: 'Ombré / Powder Brow',
    price: 600,
    buttonOutline: true,
    buttonLink: '/cosmetic-tattooing/#consultation-form',
    description: (
      <div>
        For those wanting the wake up with makeup look. Both the powder and
        ombré brow technique are great for filling in sparse patchy brows.
        Adding a little more definition and shape this technique can be
        customised to either a bold and defined or soft and natural look. This
        semi permanent procedure will last around 12-24 months depending on the
        client. This price includes a 6-8 week touch up appointment.
      </div>
    ),
    image: (
      <StaticImage
        src="../../images/brows/ombre-powder-brow.jpg"
        alt=""
        className="rounded-full"
      />
    ),
  },
  {
    id: nanoid(),
    name: 'Microblading / Feather Brow',
    price: 600,
    buttonOutline: true,
    buttonLink: '/cosmetic-tattooing/#consultation-form',
    description: (
      <div>
        Microblading is a semi permanent technique best suited to more normal
        skin types with less oil flow. Mimicking crisp individual hair strokes
        for a more natural result. This procedure will last around 12 months
        before needing a touch up. This price includes a 6-8 week touch up
        appointment.
      </div>
    ),
    image: (
      <StaticImage
        src="../../images/brows/microblading-feather-brow.jpg"
        alt=""
      />
    ),
  },
  {
    id: nanoid(),
    name: 'Combination Brow',
    price: 600,
    buttonOutline: true,
    buttonLink: '/cosmetic-tattooing/#consultation-form',
    description: (
      <div>
        The combination brow is a great choice for those wanting the appearance
        of fine hair strokes with a little colour to define the arch and tails
        of the brow. This price includes a 6-8 week touch up appointment.
      </div>
    ),
    image: <StaticImage src="../../images/brows/combination-brow.jpg" alt="" />,
  },
  {
    id: nanoid(),
    name: 'Lip Blush',
    price: 600,
    buttonOutline: true,
    buttonLink: '/cosmetic-tattooing/#consultation-form',
    description: (
      <div>
        Lip blush is a cosmetic tattoo procedure designed to restore colour,
        definition and correct minor asymmetry of the lips. For most people the
        vermillion border of the lip is lacking in pigment and by tattooing this
        area the lip will appear fuller and more defined. You can choose a
        pigment to match your natural tone or go for something a bit brighter to
        give you a pop of colour without any makeup. This price includes a 6-8
        week touch up appointment.
      </div>
    ),
    image: <StaticImage src="../../images/brows/3.jpeg" alt="" />,
  },
  {
    id: nanoid(),
    name: 'Touch Ups',
    buttonOutline: true,
    buttonLink: '/cosmetic-tattooing/#consultation-form',
    description: (
      <div>
        <ul>
          <li className="justify-between text-center md:flex">
            <div>6 - 8 Week Touch Up</div>
            <span className="text-xl font-semibold md:text-2xl">Included</span>
          </li>
          <li className="justify-between text-center md:flex">
            <div>9 - 12 Month Touch Up</div>
            <span className="text-xl font-semibold md:text-2xl">$350</span>
          </li>
          <li className="justify-between text-center md:flex">
            <div>12 - 24 Month Touch Up</div>
            <span className="text-xl font-semibold md:text-2xl">$400</span>
          </li>
          <li className="justify-between text-center md:flex">
            <div>After 24 months is charged at original price</div>
          </li>
          <li className="justify-between text-center md:flex">
            <div>
              A non-refundable deposit of $50 is required upon booking which is
              deducted from the final price.
            </div>
          </li>
        </ul>
      </div>
    ),
    image: <StaticImage src="../../images/brows/2.jpeg" alt="" />,
  },
];
